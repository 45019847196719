<template>
  <div class="card mb-3">
    <div class="card-header">
      <strong>Delete Account</strong>
    </div>
    <div class="card-body">
      <p>We believe that it is every user’s right to completely delete their data from a service if they choose to. If you do not trust us with your data, please have a look at our <a href="https://vatstack.com/privacy">privacy policy</a> to understand how serious data security is to us. You can also simply <router-link :to="{ name: 'subscription' }">switch back to the developer plan</router-link>. It’s free for life.</p>
    </div>
    <div class="card-footer">
      <a-button type="primary" @click="onCancel" class="mr-1">
        Back to Safety
      </a-button>
      <a-button type="link" :loading="loading" @click="onClick">
        Delete Anyway
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    }
  },

  methods: {
    onCancel() {
      this.$router.push({ name: 'index' })
    },

    onClick() {
      this.$confirm({
        title: 'Confirm Account Deletion',
        content: 'Once deleted, your account cannot be restored. Continue with the deletion?',
        okText: 'Delete',
        onOk: async () => {
          await this.onConfirm()
        },
        onCancel() {},
      })
    },

    async onConfirm() {
      try {
        await this.$httpd.delete(`/user`)
        await this.$httpd.delete('/session')

        await this.$store.dispatch('refreshToken')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }

      this.$message.success('Your account has been deleted.')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
